import type { InjectionKey } from 'vue'

// models
import { VISIT_TYPE, VisitType, WorldVisit } from '@/models/visitWorld'

import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  GetWorldVisitsFootprintRequest,
  getWorldVisitsFootprintResponse,
  GetWorldVisitsFootprintedRequest,
  getWorldVisitsFootprintedResponse,
} from '@/composables/repository/useVisitWorldRepository'

// modules
import { isValueOf } from '@/utils/zod'

export type VisitWorldStateType = {
  footprintList: WorldVisit[]
  footprintedList: WorldVisit[]
}

export const useVisitWorld = () => {
  const repositoryFactory = useRepositoryFactory()
  const visitWorldRepository = repositoryFactory.get('visitWorld')

  const state = useState<VisitWorldStateType>('visit_world', () => ({
    footprintList: [],
    footprintedList: [],
  }))

  // pager情報
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)
  const page = ref(0)

  /**
   * 自分が訪問したルームのユーザーの一覧を取得
   */
  const getFootprintList = async (
    vketId: string,
    params?: Omit<GetWorldVisitsFootprintRequest, 'offset'> & {
      page?: number
    }
  ) => {
    limit.value = params?.limit || limit.value
    page.value = params?.page || 1
    offset.value = page.value * limit.value - limit.value

    const response = await visitWorldRepository.get.getFootprint(vketId, {
      limit: limit.value,
      offset: offset.value,
      ...params,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getWorldVisitsFootprintResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.footprintList = response.worldVisits
    total.value = response.visitCount
  }

  /**
   * 自分のルームに訪問したユーザーの一覧を取得
   */
  const getFootprintedList = async (
    params?: Omit<GetWorldVisitsFootprintedRequest, 'offset'> & {
      page?: number
    }
  ) => {
    limit.value = params?.limit || limit.value
    page.value = params?.page || 1
    offset.value = page.value * limit.value - limit.value

    const response = await visitWorldRepository.get.getFootprinted({
      limit: limit.value,
      offset: offset.value,
      ...params,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getWorldVisitsFootprintedResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.footprintedList = response.worldVisits
    total.value = response.visitCount
  }

  /**
   * 訪問リストのフォロー状態を更新
   * @param visitType
   * @param vketId
   * @param isFollow 更新後のフォロー状態
   */
  const updateFollowState = (
    visitType: VisitType,
    vketId: string,
    isFollow: boolean
  ) => {
    if (visitType === VISIT_TYPE.FOOTPRINT) {
      const targetVisit = state.value.footprintList.filter(
        (visit) => visit.user.vketId === vketId
      )
      if (targetVisit.length) {
        targetVisit.forEach((visit) => {
          visit.user.followStatus = isFollow
        })
      }
    } else if (visitType === VISIT_TYPE.FOOTPRINTED) {
      const targetVisit = state.value.footprintedList.filter(
        (visit) => visit.user.vketId === vketId
      )
      if (targetVisit.length) {
        targetVisit.forEach((visit) => {
          visit.user.followStatus = isFollow
        })
      }
    }
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    getFootprintList,
    getFootprintedList,
    updateFollowState,
  }
}

export type VisitWorldComposable = ReturnType<typeof useVisitWorld>

export const visitWorldComposablesInjectionKey: InjectionKey<VisitWorldComposable> =
  Symbol('visit-world-composable')
